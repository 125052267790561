var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"ma-auto masraf-card",attrs:{"elevation":"0"}},[_c('v-card-title',[_c('span',{staticClass:"masraf-title"},[_vm._v("Masraf Çeşitleri")]),_c('v-spacer'),_c('v-card',{staticClass:"masraf-ekle-card",attrs:{"elevation":"0"}},[_c('form-dialog',{attrs:{"disabled":!_vm.$helper.isAuthorized('masraf-cesidi-ekle'),"tooltip":!_vm.$helper.isAuthorized('masraf-cesidi-ekle')
            ? 'Bu işlem için yetkiniz yok.'
            : '',"title":"Masraf Çeşidi Bilgileri","icon":"mdi-plus","medium":""},on:{"success":_vm.loadMasrafCesitleri},scopedSlots:_vm._u([{key:"default",fn:function({ formData, onInput, onClose, onSuccess }){return [_c('masraf-cesitleri-form',{on:{"input":onInput,"onClose":onClose,"onSuccess":onSuccess},model:{value:(formData),callback:function ($$v) {formData=$$v},expression:"formData"}})]}}])})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.masrafCesitleri,"hide-default-header":"","no-data-text":"Kayıtlı Masraf bulunamadı. Eklemek için + butonunu kullan.","dense":""},scopedSlots:_vm._u([{key:"header",fn:function({ props: { headers } }){return [_c('thead',[_c('tr',_vm._l((headers),function(h){return _c('th',{class:h.class},[_c('span',[_vm._v(_vm._s(h.text))])])}),0)])]}},{key:"item.takibe_ekle",fn:function({ item }){return [(item.takibe_ekle === true)?_c('v-icon',[_vm._v("mdi-check-box-outline")]):_c('v-icon',[_vm._v("mdi-crop-square")])]}},{key:"item.gayri_resmi",fn:function({ item }){return [(item.gayri_resmi === true)?_c('v-icon',[_vm._v("mdi-check-box-outline")]):_c('v-icon',[_vm._v("mdi-crop-square")])]}},{key:"item.kapakta_gorunsun",fn:function({ item }){return [(item.kapakta_gorunsun === true)?_c('v-icon',[_vm._v("mdi-check-box-outline")]):_c('v-icon',[_vm._v("mdi-crop-square")])]}},{key:"item.makbuzda_gorunsun",fn:function({ item }){return [(item.makbuzda_gorunsun === true)?_c('v-icon',[_vm._v("mdi-check-box-outline")]):_c('v-icon',[_vm._v("mdi-crop-square")])]}},{key:"item.actions",fn:function({ item }){return [_c('form-dialog',{attrs:{"disabled":!_vm.$helper.isAuthorized('masraf-cesidi-duzenle'),"tooltip":!_vm.$helper.isAuthorized('masraf-cesidi-duzenle')
            ? 'Bu işlem için yetkiniz yok.'
            : '',"title":"Masraf Çeşidi Bilgileri","icon":"mdi-pencil","color":"#959595","medium":""},on:{"success":_vm.loadMasrafCesitleri},scopedSlots:_vm._u([{key:"default",fn:function({ formData, onInput, onClose, onSuccess }){return [_c('masraf-cesitleri-form',{on:{"input":onInput,"onClose":onClose,"onSuccess":onSuccess},model:{value:(formData),callback:function ($$v) {formData=$$v},expression:"formData"}})]}}],null,true),model:{value:(item),callback:function ($$v) {item=$$v},expression:"item"}}),_c('delete-dialog',{attrs:{"action":`/api/v1/masraf-cesidi/${item.id}`,"disabled":!_vm.$helper.isAuthorized('masraf-cesidi-sil'),"tooltip":!_vm.$helper.isAuthorized('masraf-cesidi-sil')
            ? 'Bu işlem için yetkiniz yok.'
            : ''},on:{"success":_vm.loadMasrafCesitleri}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }