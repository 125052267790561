
import {Component, Vue} from "vue-property-decorator";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import {VekaletUcretDonemEntity} from "@/entity/VekaletUcretDonemEntity";
import {AxiosResponse} from "axios";

@Component({
  components: {FormDialog, DeleteDialog},
})
export default class VekaletUcretleriList extends Vue {
  items: VekaletUcretDonemEntity[] = [];
  selectedItem: VekaletUcretDonemEntity = new VekaletUcretDonemEntity();

  headers = [
    {text: "Alt Sınır", value: "alt_sinir",align:'start'},
    {text: "Üst Sınır", value: "ust_sinir",align:'start'},
    {text: "Borçlu Oran", value: "borclu_oran",align:'center'},
  ];

  mounted() {
    this.loadVekaletUcretleri();
  }

  loadVekaletUcretleri() {
    this.$http.get("/api/v1/vekalet-ucret-donemi").then((response: AxiosResponse<Array<VekaletUcretDonemEntity>>) => {
      this.items = response.data;
      this.items.reverse();
      this.selectedItem = this.items[0];

      this.items.forEach((donem, index) => {
        donem.text = donem.baslangic_tarihi ? this.$helper.tarihStr(donem.baslangic_tarihi) : "";
        donem.oranlar?.forEach((oran, sayi) => {
          let donem = this.items[index]
          if (donem && donem.oranlar) {
            if (donem.oranlar[sayi + 1] && donem.oranlar[sayi] && sayi < donem.oranlar.length - 1) {
              donem.oranlar[sayi].ust_sinir = donem.oranlar[sayi + 1].alt_sinir - 1;
            }
          }
        });
      });
    });
  }
}
