
import { Component, Prop, Vue } from "vue-property-decorator";
import { HarcTuruEntity } from "@/entity/HarcTuruEntity";

@Component({
  components: {},
})
export default class HarcCesitleriList extends Vue {
  @Prop() items!: HarcTuruEntity[];
  tabs = ["tahsil", "diger"];
  tab = 0;
  headers = [
    {
      text: "Harc",
      value: "harc_turu.isim",
      class: "my-header-style",
    },
    {
      text: "Oran",
      value: "oran",
      class: "my-header-style",
    },
    {
      text: "Tutar",
      value: "tutar",
      align: "end",
      class: "header-actions-style",
    },
  ];

  get shownHeaders() {
    if (this.tabs[this.tab] == "tahsil") {
      return this.headers.slice(0, 2);
    }
    return this.headers;
  }

  get shownItems() {
    if (!this.items) {
      return null;
    }

    return this.items.filter(
      (x: any) => x.harc_turu.harc_tipi === this.tabs[this.tab]
    );
  }
}
