
import {Component, Prop, Vue} from "vue-property-decorator";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import TakipEtiketiForm from "@/components/forms/TakipEtiketiForm.vue";
import {TakipEtiketiEntity} from "@/entity/TakipEtiketiEntity";
import {TakipDurumuLabel} from "@/enum/TakipDurumu";

@Component({
  components: {TakipEtiketiForm, FormDialog, DeleteDialog},
})
export default class TakipEtiketiList extends Vue {
  @Prop() editable!: boolean;
  items: Array<TakipEtiketiEntity> = [];
  TakipDurumuLabel = TakipDurumuLabel;

  headers = [
    {text: "Durum Adı", value: "isim", align: "start"},
    {text: "Takip Durumu", value: "takip_durumu", align: "start"},
    {text: "İşlemler", value: "actions", width: 100, sortable: false, align: 'end'},
  ]

  mounted() {
    this.loadTakipEtiketleri();
  }

  loadTakipEtiketleri() {
    this.$http.get("/api/v1/takip-etiketi").then((response) => {
      this.items = response.data;
    });
  }
}
