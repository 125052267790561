
import { Component, Vue } from "vue-property-decorator";
import MasrafCesitleriForm from "@/components/forms/MasrafCesitleriForm.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";

@Component({
  components: { FormDialog, MasrafCesitleriForm, DeleteDialog },
})
export default class MasrafCesitleriList extends Vue {
  masrafCesitleri = [];
  search: string = "";
  headers = [
    {
      text: "Masraf Çeşidi",
      align: "start",
      sortable: false,
      value: "isim",
      class: "my-header-style",
    },
    {
      text: "Tutar",
      align: "start",
      sortable: false,
      value: "tutar",
      class: "my-header-style",
    },
    {
      text: "Otomatik Ekle",
      value: "takibe_ekle",
      width: 30,
      class: "my-header-style",
    },
    {
      text: "Gayriresmi",
      value: "gayri_resmi",
      width: 30,
      class: "my-header-style",
    },
    {
      text: "Kapakta Görünsün",
      value: "kapakta_gorunsun",
      width: 30,
      class: "my-header-style",
    },
    {
      text: "Makbuzda Görünsün",
      value: "makbuzda_gorunsun",
      width: 30,
      class: "my-header-style",
    },
    {
      text: "İşlemler",
      value: "actions",
      sortable: false,
      width: 100,
      align: "end",
      class: "header-actions-style",
    },
  ];

  mounted() {
    this.loadMasrafCesitleri();
  }

  loadMasrafCesitleri() {
    this.$http.get("/api/v1/masraf-cesidi").then((response) => {
      this.masrafCesitleri = response.data;
    });
  }
}
