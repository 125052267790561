
import { Component, Vue } from "vue-property-decorator";
import HarclarInfo from "@/components/infos/HarclarInfo.vue";
import VekaletUcretleriList from "@/components/lists/VekaletUcretleriList.vue";
import MasrafCesitleriList from "@/components/lists/MasrafCesitleriList.vue";
import TakipAltTipList from "@/components/lists/TakipAltTipList.vue";
import FaizTuruList from "@/components/lists/FaizTuruList.vue";
import TakipEtiketiList from "@/components/lists/TakipEtiketiList.vue";
import AltTahsilatTuruList from "@/components/lists/AltTahsilatTuruList.vue";
import TakipEkAlanList from "@/components/lists/TakipEkAlanList.vue";
import NotSablonList from "@/components/lists/NotSablonList.vue";

@Component({
  components: {
    NotSablonList,
    TakipEkAlanList,
    AltTahsilatTuruList,
    TakipEtiketiList,
    FaizTuruList,
    HarclarInfo,
    VekaletUcretleriList,
    TakipAltTipList,
    MasrafCesitleriList,
  },
})
export default class TercihlerView extends Vue {
  activeTab = 0;
}
